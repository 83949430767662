// WalletPage.js
import React from "react";
import { Container } from "reactstrap";
import MyNavbar from "../components/navbar"; // Asegúrate de que la ruta sea correcta
import PurchasedBonds from "../components/purchasedBonds"; // Asegúrate de que la ruta sea correcta

function MyBondsPage() {
  return (
    <Container>
      <div>
        <MyNavbar />
      </div>
      <div>
        <PurchasedBonds />
      </div>
    </Container>
  );
}

export default MyBondsPage;
